import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/dataService/dataService.service';
import { FormGroup, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { interfaces } from '@leandredev/adaptivai-common-lib';
import { ApiHelper } from '@leandredev/common-ng16/httpClient';
import { BaseComponent } from '@leandredev/common-ng16/infraService';

import { form } from '@leandredev/adaptivai-ng16-lib';
import { CommonModule } from '@angular/common';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { UserClientInvitationFormModule } from 'src/app/generated/ui/form/UserClientInvitation/UserClientInvitation.module';
@Component({
  selector: 'app-user-client-new',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FurySharedModule, FuryCardModule, UserClientInvitationFormModule],
  templateUrl: './user-client-new.component.html',
  styleUrls: ['./user-client-new.component.scss']
})
export class UserClientNewComponent extends BaseComponent implements OnInit {
  public userRoles: interfaces.IUserRole[] = [
    {
      _id: '6798a818f7217afc3925fd9b',
      _class: 'UserRole',
      name: 'documentalist_user',
      description: 'Allows the user to use the Documentalist application'
    },
    {
      _id: '6798a883c1d67ec041014d6b',
      _class: 'UserRole',
      name: 'copywriter_user',
      description: 'Allows the user to use the Copywriter application'
    },
    {
      _id: '67a48bf3fcb4449be77d1813',
      _class: 'UserRole',
      name: 'creativeLegacy_user',
      description: 'Allows the user to use the Creative application'
    },
    {
      _id: '6798a8e6e5ac642212abd623',
      _class: 'UserRole',
      name: 'analyst_user',
      description: 'Allows the user to use the Analyst application'
    },
    {
      _id: '6798a90eb7e1c0927d500e43',
      _class: 'UserRole',
      name: 'editor_user',
      description: 'Allows the user to use the Editor application'
    },
    {
      _id: '6798a9695807594628b87be4',
      _class: 'UserRole',
      name: 'coach_user',
      description: 'Allows the user to use the Coach application'
    },
    {
      _id: '6798a9dc062e04197171d320',
      _class: 'UserRole',
      name: 'chat_user',
      description: 'Allows the user to use the Chat application'
    },
    {
      _id: '6798a9c2e843c39b72aac5b2',
      _class: 'UserRole',
      name: 'flower_user',
      description: 'Allows the user to use the Flower application'
    }
  ];
  public currentEditName = '';
  public userclientGroups: interfaces.IUserClientGroup[] = [];
  public gap = 'px';
  public invitationsResult: interfaces.IUserClientInvitationResponse;
  public allUserformGroup: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService
  ) {
    super();
    this.allUserformGroup = this.fb.group({ users: this.fb.array([]), name: 'users' });
  }
  ngOnInit(): void {
    this.subscription.add(
      this.dataService.api.collections.UserClientGroup.get()
        .pipe(ApiHelper.resultToArr('pas de grpus'))
        .subscribe((groups) => {
          this.userclientGroups = groups;
        })
    );
  }

  public save(): void {
    const invitations: interfaces.IUserClientInvitationResquest = { invitations: [] };

    if (this.users?.value) {
      invitations.invitations = this.users.value as interfaces.IUserClientInvitation[];
      this.dataService.admin
        .invitations(invitations, {}, {})
        .pipe(ApiHelper.resultToObj(''))
        .subscribe({
          next: (result) => {
            this.setInvitationsResponse(result);
          },
          error: () => {
            // NOT IMPLEMENTED
          }
        });
    }
  }

  get users(): FormArray {
    return this.allUserformGroup.get('users') as FormArray;
  }

  newUser(): FormGroup {
    const roles: string[] = this.userRoles.map((role) => role._id);
    const userForm = new form.UserClientInvitation();
    userForm.patchValue({ createdAt: new Date(), roles: roles });
    return userForm;
  }

  public addUser(): void {
    this.users.push(this.newUser());
  }

  public removeUser(i: number): void {
    this.users.removeAt(i);
  }

  private setInvitationsResponse(data: any): void {
    this.users.clear();
    this.invitationsResult = data;
  }
}
