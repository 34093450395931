import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IsSignedInGuard } from './guards/is-signed.guard';
import { LayoutComponent } from './layout/layout.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserClientNewComponent } from './modules/user-client-new/user-client-new.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsSignedInGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        pathMatch: 'full'
      },
      {
        path: 'data',
        loadChildren: () => import('./data/data.module').then((m) => m.DataModule)
      },
      {
        path: 'ResearcherActivity',
        loadComponent: () => import('./modules/researcher-activity/researcher-activity.component').then((m) => m.ResearcherActivityComponent)
      },
      {
        path: 'stats',
        loadComponent: () => import('./app-specific/stats-export/stats-export.component').then((m) => m.StatsExportComponent)
      },
      {
        path: 'data-privacy',
        loadChildren: () => import('./modules/data-privacy/data-privacy.module').then((m) => m.DataPrivacyModule)
      },
      {
        path: 'user-client-new',
        component: UserClientNewComponent
      }
    ]
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then((m) => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/authentication/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',

      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
    DashboardModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
